import * as React from 'react'
import { connect } from 'react-redux'
import Layout from '../components/layout'
import Seo from '../components/seo'
import { createUseStyles } from 'react-jss'
import PropTypes from 'prop-types'
import SectionTitle from '../components/atoms/SectionTitle'
import ImageCarousel from '../components/atoms/ImageCarousel'
import IndexBottomSection from '../components/organisms/IndexBottomSection'
import AreaSeoSection from '../components/molecules/AreaSeoSection'

const AreaPage = (props) => {
  const { translations, path, } = props
  const classes = useStyles()

  return (
  <Layout path={path}>
    <Seo title={translations.page_title} description={translations.page_description} />
    <div className={classes.pageConainer}>
      <SectionTitle title={translations.section_title} subtitle={translations.section_subtitle} color='#94d224' backgroundColor='#94d224' />
      <div className={classes.imgSection}>
        <div className={classes.infoBox}>
          <p> {translations.area_description} </p>
          <div className={classes.seeMore}>
            <a href="https://www.dobczyce.pl/" rel="noreferrer" target="_blank"> {translations.learn_more} </a>
          </div>
        </div>
        <div className={classes.imgBox}>
          <ImageCarousel page='area' alt={translations.image_alt} styles={{ width: '96%', height: '350px', }}/>
        </div>
      </div>
      <IndexBottomSection />
      <AreaSeoSection />
    </div>
  </Layout>
  )
}

const useStyles = createUseStyles({
  pageConainer: {
    maxWidth: '1200px',
    margin: 'auto',
    padding: '1em',
  },
  imgSection: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    marginTop: '5em',
  },
  imgBox: {
    width: '50%',
  },
  infoBox: {
    boxSizing: 'border-box',
    backgroundColor: 'white',
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginBottom: '3em',
    border: '2px dashed #94d224',
    '& p': {
      fontSize: '20px',
      lineHeight: '150%',
      padding: '0em 1em',
      paddingTop: '1em',
      color: '#141414',
    },
  },
  seeMore: {
    backgroundColor: '#94d224',
    height: '50px',
    border: '2px solid #94d224',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& a': {
      textDecoration: 'none',
      color: 'white',
      fontSize: '19px',
    },
  },
  '@media(max-width: 960px)': {
    infoBox: {
      width: '100%',
    },
    imgBox: {
      width: '100%',
    },
  },
})

AreaPage.propTypes = {
  translations: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
}

const mapStateToProps = (state) => {
  return {
    translations: state.translations.translations.area_page,
  }
}

export default connect(mapStateToProps)(AreaPage)
